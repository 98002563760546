<template>
  <div class="look_space">
      <ds-header title="查看空间"></ds-header>
      <a-button type="link" class="back_btn" @click="$router.go(-1)">
        返回
      </a-button>
    <a-form-model
      :layout="'horizontal'"
      ref="ruleForm"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }"
      :colon="false"
    >
      <a-row>
          <a-col :span="12">
          <a-form-model-item label="当前项目：">
            <div>{{ parameter.name || '-'}}</div>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="空间位置：">
            {{ result.spacePlace || '-'}}
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="空间类型：">
            <div>{{ spaceType(result.spaceType) || '-'}}</div>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="空间名称：">
            {{ result.spaceName || '-'}}
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row v-if="result.spaceType == 2">
        <a-col :span="12">
          <a-form-model-item label="房产证号：">
            <div >{{ result.houseNumber || '-' }}</div>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row v-if="result.spaceType == 2 || result.spaceType == 3">
        <a-col :span="12">
          <a-form-model-item label="楼栋类型：">
            <div v-if="result.buildingType">{{ buildingType(result.buildingType) }}</div>
            <div v-else>-</div>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="建筑结构：">
            <div v-if="result.structureType">{{ structureType(result.structureType) }}</div>
            <div v-else>-</div>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row v-if="result.spaceType == 2 || result.spaceType == 3">
        <a-col :span="12">
          <a-form-model-item label="层高：">
            <div v-if="result.storeyHeight != null">{{ result.storeyHeight+'米' }}</div>
            <div v-else>-</div>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="承重：">
            <div v-if="result.bearing != null">{{ result.bearing+'KN/㎡' }}</div>
            <div v-else>-</div>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row v-if="result.spaceType == 2  || result.spaceType == 3">
        <a-col :span="12">
          <a-form-model-item label="建筑面积：">
            <div v-if="result.buildArea != null">{{ (result.buildArea ? result.buildArea : '0') + '㎡'}}</div>
            <div v-else>-</div>
          </a-form-model-item>
        </a-col>
        <a-col :span="12" v-if="result.spaceType == 2">
          <a-form-model-item label="地址坐标：">
            <div v-if="result.longitude">{{ result.longitude }}&nbsp;&nbsp;{{ result.latitude }}</div>
            <div v-else>-</div>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row v-if="result.spaceType == 2 || result.spaceType == 3">
        <a-col :span="12">
          <a-form-model-item label="可用面积：">
            <div v-if="result.usableArea != null">{{ result.usableArea + '㎡'}}</div>
            <div v-else>-</div>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="不可用面积：">
            <div v-if="result.unusableArea != null">{{ result.unusableArea + '㎡'}}</div>
            <div v-else>-</div>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row v-if="result.spaceType == 2 || result.spaceType == 3">
        <a-col :span="12">
          <a-form-model-item label="可租面积：">
            <div v-if="result.rentableArea != null">{{ result.rentableArea + '㎡'}}</div>
            <div v-else>-</div>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="不可租面积：">
            <div v-if="result.unrentableArea != null">{{ result.unrentableArea + '㎡'}}</div>
            <div v-else>-</div>
          </a-form-model-item>
        </a-col>
      </a-row>
     
      <a-row v-if="result.spaceType == 4">
        <a-col :span="12">
          <a-form-model-item label="房间类型：">
            <div v-if="result.roomType">{{ roomType(result.roomType) }}</div>
            <div v-else>-</div>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="套内面积：">
            <div v-if="result.insideSpace != null">{{ result.insideSpace + '㎡'}}</div>
            <div v-else>-</div>
          </a-form-model-item>
        </a-col>
      </a-row>
      <!-- <a-row v-if="result.spaceType == 4">
        <a-col :span="12">
          <a-form-model-item label="公摊面积：">
            <div v-if="result.publicArea != null">{{ result.publicArea + '㎡'}}</div>
            <div v-else>-</div>
          </a-form-model-item>
        </a-col>
      </a-row> -->
      <a-row v-if="result.spaceType == 5 || result.spaceType == 6">
        <a-col :span="12">
          <a-form-model-item label="面积：">
            <div>
              <span v-if="result.area != null">{{ result.area + '㎡'}}</span>
              <span v-else>-</span>
              <span v-if="result.spaceType == 6">/{{ parseFloat(((result.area) / 666.6667).toFixed(5)) + '亩'}}</span>
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row v-if="result.spaceType == 3">
        <a-col :span="12">
          <a-form-model-item label="平面图：">
            <!-- <div style="display:flex">
              <a-modal :visible="previewVisible" :footer="null" @cancel="previewCancel">
              <img style="width:110px;height:110px;margin-left:10px" v-for="item in result.planImageUrl.split(',')" :src="item" :key="item">
            </a-modal>
            </div> -->
            <a-upload
              :action="IMG_API + '/oss/files'"
              list-type="picture-card"
              :file-list="fileList"
              :before-upload="beforeUpload"
              accept=".jpg,.png,.jpeg"
              @preview="handlePreview"
              :showUploadList={showRemoveIcon:false}
              @change="handleChange"
            >
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
        </a-form-model-item>
        </a-col>
        
      </a-row>
    </a-form-model>
  </div>
</template>
  
<script>
import { IMG_API } from '@/config/index';
import * as api from '@/api/baseSet/spaceManage';
export default {
  name: 'lookSpace',
  data() {
    return {
      IMG_API: IMG_API,
      fileList:[],
      form: {
        deviceTypeFlag: undefined,
        providerCode: undefined,
        backGroundImageUrl: [],
      },
      previewVisible: false,
      previewImage: '',
      spaceId:'',
      parameter:{
        spaceId:'',
        space_place:"",
        name:""
      },
      result:{},
    };
  },
  created() {
    if(this.$route.query.spaceId){
      this.parameter = this.$route.query
      this.getInit()
    }
  },
  methods: {
   async getInit() {
      let res = await api.getSpaceDetailInfo({
        spaceId:this.parameter.spaceId
      })
      if(res.code == 200){
        this.result = res.data
        if(this.result.planImageUrl != null){
          let urls = this.result.planImageUrl.split(',')
          this.fileList = urls.map((item,index) => ({
              uid: index+1,
              name: item.substring(item.lastIndexOf('/') + 1),
              status: 'done',
              url: item,
              response:{
                redirect_uri:item
              }
          }));
        }
        
      }
    },
    previewCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      this.previewImage = file.url || file.response.redirect_uri;
      this.previewVisible = true;
    },
    handleCancel() {
      this.previewVisible = false;
    },
    //空间类型
    spaceType(type) {
      return {
        1:'区域',2:'楼栋',3:"楼层",4:"房间",5:"其他",6:"土地"
      }[type]
    },
    buildingType(type) {//楼栋类型
      return {
        1:'办公室',2:'厂房',3:"其他"
      }[type]
    },
    structureType(type) {//建筑结构
      return {
        1:'钢结构',2:'钢筋混凝土结构',3:"砖混结构",4:"框架结构",5:"轻钢结构",6:"其他",7:'钢筋混凝土框架结构',8:'钢框架结构'
      }[type]
    },
    roomType(type) {
      return {
        1:'可租',2:'自用',3:"公益",4:"其他"
      }[type]
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.look_space {
  position: relative;
  .back_btn{
    position: absolute;
    right: 5px;
    top: 20px;
  }
  .content {
    color: #000;
    font-weight: bold;
    line-height: 20px;
    margin-top: 10px;
    overflow-wrap: anywhere;
  }

  .footer {
    margin-top: 30px;
    margin-left: 100px;
  }

  .hint-text {
    height: 20px;
    line-height: 20px;
    color: #8e8e8e;
    font-size: 12px;
    margin-top: 10px;
  }

  .hint-text p:nth-of-type(2) {
    margin-top: -13px;
  }

  .device-text {
    color: #8e8e8e;
    font-size: 12px;
    line-height: 18px;
    margin-top: 20px;

    // margin-bottom: -30px;
    p {
      margin-top: -8px;
    }
  }
}
</style>
  